import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Ewallet = Loadable(lazy(() => import("src/pages/financial/ewallet")));
const DepositWallet = Loadable(
  lazy(() => import("src/pages/financial/deposit"))
);
const FundCredits = Loadable(
  lazy(() => import("src/pages/financial/fundCredit"))
);
const Payout = Loadable(
  lazy(() => import("src/pages/financial/payout/payout"))
);
const FinancialReport = Loadable(
  lazy(() => import("src/pages/financial/financialReport/financialReport"))
);
const AddAnalsyt = Loadable(
  lazy(() =>
    import(
      "src/pages/financial/financialReport/components/financialReports/AddAnalyst/Index"
    )
  )
);
const AddAdminExpense = Loadable(
  lazy(() =>
    import(
      "src/pages/financial/financialReport/components/financialReports/AddAdminExpense/index.js"
    )
  )
);
const AddAdminExpenseEdit = Loadable(
  lazy(() =>
    import(
      "src/pages/financial/financialReport/components/financialReports/AddAdminExpense/EditAdminExpense"
    )
  )
);
const AnalystFinancialReport = Loadable(
  lazy(() =>
    import(
      "src/pages/financial/financialReport/components/AnalystFinancialReport/index.js"
    )
  )
);
const AddAnalystExpense = Loadable(
  lazy(() =>
    import(
      "src/pages/financial/financialReport/components/AddAnalystExpense/Index"
    )
  )
);
const AffiliateDashboard = Loadable(
  lazy(() => import("src/pages/financial/AffiliateDashboard/Index"))
);

const EditAnalystExpense = Loadable(
  lazy(() =>
    import(
      "src/pages/financial/financialReport/components/AddAnalystExpense/EditAnalystExpense"
    )
  )
);
const EditAnalyst = Loadable(
  lazy(() =>
    import(
      "src/pages/financial/financialReport/components/financialReports/AddAnalyst/EditAnalyst"
    )
  )
);

const InvoiceDetails = Loadable(
  lazy(() => import("src/pages/dashboard/InvoiceDetails"))
);

const RevenuePlanExpense = Loadable(
  lazy(() =>
    import("src/pages/financial/financialReport/revenue-plan-expense/index")
  )
);

const financial = [
  {
    path: "financial",
    children: [
      { element: <Navigate to="e-wallet" />, index: true },
      { path: "e-wallet", element: <Ewallet /> },
      { path: "deposit-wallet", element: <DepositWallet /> },
      { path: "fund-credits", element: <FundCredits /> },
      { path: "payout", element: <Payout /> },
      {
        path: "report",
        children: [
          {
            element: <FinancialReport />,
            index: true,
          },
          { path: "revenue-plan-expense", element: <RevenuePlanExpense /> },
          {
            path: "analyst",
            children: [
              { index: true, element: <AddAnalsyt /> },
              { path: ":id", element: <EditAnalyst /> },
            ],
          },
          {
            path: "add-admin-expense",
            children: [
              { index: true, element: <AddAdminExpense /> },
              { path: ":id", element: <AddAdminExpenseEdit /> },
            ],
          },
          {
            path: "analyst-financial-report",
            children: [
              { index: true, element: <AnalystFinancialReport /> },
              { path: "add", element: <AddAnalystExpense /> },
              { path: "invoice/:id", element: <InvoiceDetails /> },
              { path: ":id", element: <EditAnalystExpense /> },
            ],
          },
        ],
      },

      { path: "affiliate-dashboard", element: <AffiliateDashboard /> },
    ],
  },
];

export default financial;
