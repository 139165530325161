import { PATH_DASHBOARD, PATH_USER } from "src/routes/paths";
const getIconURI = (name) => `/icons/${name}.svg`;

const ICONS = {
  hodlnots: getIconURI("ic_hodlnots"),
  report: getIconURI("ic_report"),
  assignSubscriptions: getIconURI("ic_assignSubscription"),
  blog: getIconURI("ic_blog"),
  cart: getIconURI("ic_cart"),
  chat: getIconURI("ic_chat"),
  mail: getIconURI("ic_mail"),
  user: getIconURI("ic_user"),
  kanban: getIconURI("ic_kanban"),
  banking: getIconURI("ic_banking"),
  booking: getIconURI("ic_booking"),
  invoice: getIconURI("ic_invoice"),
  calendar: getIconURI("ic_calendar"),
  ecommerce: getIconURI("ic_ecommerce"),
  analytics: getIconURI("ic_analytics"),
  dashboard: getIconURI("ic_dashboard"),
  settings: getIconURI("ic_settings"),
  tools: getIconURI("ic_tools"),
  member_management: getIconURI("ic_member_management"),
  subadmin: getIconURI("ic_subadmin"),
  holdingtank: getIconURI("ic_holdingtank"),
  store: getIconURI("ic_store"),
  user_subscription: getIconURI("ic_user_subscription"),
  report: getIconURI("ic_report"),
  online_store: getIconURI("ic_store"),
  my_subscription: getIconURI("ic_user_subscription"),
  business_builder: getIconURI("ic_bb"),
  profile: getIconURI("ic_profile"),
  help_center: getIconURI("ic_helpcenter"),
  tafToken: getIconURI("ic_taf-token"),
  nft: getIconURI("ic_nft"),
};

export const userNavConfig = [
  {
    items: [
      {
        affilate: false,
        title: "dashboard",
        path: PATH_USER.user_dashboard,
        icon: ICONS.dashboard,
      },

      {
        affilate: false,
        title: "joinTelegram",
        path: "https://t.me/TheBitcointafBOT",
        icon: ICONS.chat,
      },

      {
        affilate: false,
        title: "onlineStore",
        path: PATH_USER.onlineStore.root,
        icon: ICONS.online_store,
        children: [
          {
            title: "product",
            path: PATH_USER.onlineStore.productSubscription.root,
          },
          { title: "myOrders", path: PATH_USER.my_orders.root },
        ],
      },
      {
        affilate: false,
        title: "mySubscriptions",
        icon: ICONS.my_subscription,
        path: PATH_USER.subscriptions.root(),
      },

      {
        affilate: false,
        title: "businessBuilder",
        icon: ICONS.business_builder,
        path: PATH_USER.business_builder.root,
        children: [
          {
            affilate: true,
            title: "Affiliate Dashboard",
            path: PATH_USER.business_builder.genealogy.affiliate,
          },

          {
            affilate: true,
            title: "Business Subscription",
            path: PATH_USER.business_builder.subscriptions.root,
          },

          {
            affilate: true,
            title: "Genealogy Binary",
            path: PATH_USER.business_builder.genealogy.binary,
          },
          {
            affilate: true,
            title: "Genealogy Binary Settings",
            path: PATH_USER.business_builder.genealogy.binaryLegSettings,
          },
          {
            affilate: true,
            title: "Genealogy Sponsor",
            path: PATH_USER.business_builder.genealogy.sponsor,
          },
          {
            affilate: true,
            title: "materials",
            path: PATH_USER.business_builder.materials.root,
          },
          {
            affilate: false,
            title: "My Deposit Wallet",
            path: PATH_USER.business_builder.financial.depositWallet,
          },
          {
            affilate: true,
            title: "My E-Wallet",
            path: PATH_USER.business_builder.financial.eWallet,
          },
          {
            affilate: true,
            title: "My Fund Transfers",
            path: PATH_USER.business_builder.financial.myFundTransfers,
          },
          {
            affilate: true,
            title: "My Payouts",
            path: PATH_USER.business_builder.financial.myPayout,
          },
        ],
      },
      // {
      //   affilate: true,
      //   title: "genealogy",
      //   icon: ICONS.kanban,
      //   path: PATH_USER.genealogy.root,
      //   children: [
      //     { title: "binary", path: PATH_USER.genealogy.binary },
      //     { title: "Sponsor", path: PATH_USER.genealogy.sponsor },
      //     {
      //       title: "affiliateDashboard",
      //       path: PATH_USER.genealogy.affiliate,
      //     },
      //     {
      //       title: "binaryLegSettings",
      //       path: PATH_USER.genealogy.binaryLeg,
      //     },
      //   ],
      // },
      // {
      //   affilate: true,
      //   title: "financial",
      //   path: PATH_USER.financial.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     {
      //       title: "myEWallet",
      //       path: PATH_USER.financial.eWallet,
      //     },
      //     {
      //       title: "depositWallet",
      //       path: PATH_USER.financial.depositWallet,
      //     },
      //     {
      //       title: "fundTransfer",
      //       path: PATH_USER.financial.fundsTransfer,
      //     },
      //     {
      //       title: "requestPayout",
      //       path: PATH_USER.financial.payout,
      //     },
      //   ],
      // },
      // {
      //   affilate: true,
      //   title: "businessBuilder",
      //   icon: ICONS.business_builder,
      //   path: PATH_USER.business_builder.root,
      //   children: [
      //     {
      //       title: "subscriptions",
      //       path: PATH_USER.business_builder.subscriptions.root,
      //     },
      //     {
      //       title: "order_history",
      //       path: PATH_USER.business_builder.history,
      //     },
      //     {
      //       title: "materials",
      //       path: PATH_USER.business_builder.materials.root,
      //     },
      //   ],
      // },
      {
        affilate: false,
        title: "myProfile",
        icon: ICONS.profile,
        path: PATH_USER.profile.root,
      },
      {
        affilate: false,
        title: "blogs",
        icon: ICONS.blog,
        path: PATH_USER.blogs.root,
      },
      {
        affilate: false,
        title: "helpCenter",
        path: PATH_USER.helpCenter.root,
        icon: ICONS.help_center,
        children: [
          {
            title: "createTicket",
            path: PATH_USER.helpCenter.createTicket.subCategory(),
          },
          {
            title: "faqs",
            path: PATH_USER.helpCenter.faq.root,
          },
          // {
          //   title: "knowledgeBase",
          //   path: PATH_USER.helpCenter.knowledgeBase.root,
          // },
          // {
          //   title: "emails",
          //   path: PATH_USER.helpCenter.mails.all,
          // },
          {
            title: "documents",
            path: PATH_USER.helpCenter.doc.root,
          },
          {
            title: "videos",
            path: PATH_USER.helpCenter.videos.root,
          },
        ],
      },
      {
        affilate: true,
        title: "incomeReport",
        path: PATH_USER.incomeReport.root,
        icon: ICONS.report,
      },
      {
        affilate: false,
        title: "Buy BTAF Token",
        path: "https://btaftoken.io/",
        icon: ICONS.tafToken,
      },
      {
        affilate: false,
        title: "Buy NFTs",
        path: "https://bitcointaf.com/btaf-token-nfts/",
        icon: ICONS.nft,
      },
    ],
  },
];

export const navConfig = [
  {
    items: [
      {
        title: "dashboard",
        placement: 0,
        path: PATH_DASHBOARD.dashboard.root,
        icon: ICONS.dashboard,
        children: [
          {
            parent: "dashboard",
            title: "business",
            placement: 0,
            path: PATH_DASHBOARD.dashboard.business,
          },
          {
            parent: "dashboard",
            title: "network",
            placement: 1,
            path: PATH_DASHBOARD.dashboard.network,
          },
        ],
      },
      {
        title: "assignSubscriptions",
        placement: 1,
        path: PATH_DASHBOARD.assignSubscription.root,
        icon: ICONS.assignSubscriptions,
        children: [
          {
            parent: "assignSubscriptions",
            title: "addBusinessBuilderFee",
            placement: 0,
            path: PATH_DASHBOARD.assignSubscription.business_builder_fee,
            actions: ["add-edit", "delete"],
          },
          {
            parent: "assignSubscriptions",
            title: "Add Product",
            placement: 1,
            path: PATH_DASHBOARD.assignSubscription.add_product,
            actions: ["add-edit", "delete", "block"],
          },
        ],
      },
      {
        title: "communication",
        placement: 2,
        path: PATH_DASHBOARD.communication.root,
        icon: ICONS.chat,
        children: [
          {
            parent: "communication",
            title: "blog",
            placement: 0,
            path: PATH_DASHBOARD.communication.blog,
            actions: ["add", "draft", "category", "edit", "delete"],
          },
          {
            parent: "communication",
            title: "faqs",
            placement: 1,
            path: PATH_DASHBOARD.communication.com_faqs,
            actions: [
              "add-edit",
              "delete_faq",
              "category_add_edit",
              "delete_category",
            ],
          },
          {
            parent: "communication",
            title: "emails",
            placement: 2,
            path: PATH_DASHBOARD.communication.mails,
            actions: ["send", "delete"],
          },
          {
            parent: "communication",
            title: "helpCenterr",
            placement: 3,
            path: PATH_DASHBOARD.communication.help_center,
          },
          // {
          //   parent: "communication",
          //   title: "article",
          //   placement: 4,
          //   path: PATH_DASHBOARD.communication.com_article,
          // },
        ],
      },
      {
        title: "financial",
        placement: 3,
        path: PATH_DASHBOARD.financial.root,
        icon: ICONS.ecommerce,
        children: [
          {
            parent: "financial",
            title: "ewallet",
            placement: 0,
            path: PATH_DASHBOARD.financial.ewallet,
          },
          {
            parent: "financial",
            title: "depositWallet",
            placement: 1,
            path: PATH_DASHBOARD.financial.deposit_wallet,
          },
          {
            parent: "financial",
            title: "financialReport",
            placement: 2,
            path: PATH_DASHBOARD.financial.report.root,
            actions: [
              "save",
              "business-builder",
              "analyst",
              "expense",
              "impersonate",
            ],
          },
          {
            parent: "financial",
            title: "fundCredits",
            placement: 3,
            path: PATH_DASHBOARD.financial.fund_credits,
            actions: ["deduct", "add"],
          },
          {
            parent: "financial",
            title: "payout",
            placement: 4,
            path: PATH_DASHBOARD.financial.payout,
            actions: ["reject", "approve"],
          },
        ],
      },

      {
        title: "genealogy",
        placement: 4,
        path: PATH_DASHBOARD.genealogy.root,
        icon: ICONS.kanban,
        children: [
          {
            parent: "genealogy",
            title: "binary",
            placement: 0,
            path: PATH_DASHBOARD.genealogy.binary,
          },
          {
            parent: "genealogy",
            title: "sponsor",
            placement: 1,
            path: PATH_DASHBOARD.genealogy.sponsor,
          },
          {
            parent: "genealogy",
            title: "tree",
            placement: 2,
            path: PATH_DASHBOARD.genealogy.tree,
          },
          {
            parent: "genealogy",
            title: "affiliate_dashboard",
            placement: 5,
            path: PATH_DASHBOARD.financial.affiliate,
          },
        ],
      },
      {
        title: "hodlnots_academy",
        placement: 5,
        path: PATH_DASHBOARD.hodlnots.root,
        icon: ICONS.hodlnots,
        children: [
          {
            parent: "hodlnots_academy",
            title: "pre-course_users",
            placement: 0,
            path: PATH_DASHBOARD.hodlnots.preCourseUsers,
          },
        ],
      },
      {
        title: "membersManagement",
        placement: 6,
        path: PATH_DASHBOARD.members.root,
        icon: ICONS.member_management,
        children: [
          {
            parent: "membersManagement",
            title: "networkMembers",
            placement: 0,
            path: PATH_DASHBOARD.members.network,
          },
          {
            parent: "membersManagement",
            title: "holdingTank",
            placement: 1,
            path: PATH_DASHBOARD.members.holdingTank,
            actions: ["add"],
          },
        ],
      },
      {
        title: "reports",
        placement: 7,
        path: PATH_DASHBOARD.report.root,
        icon: ICONS.report,
        children: [
          {
            parent: "reports",
            title: "builderSubscription",
            placement: 0,
            path: PATH_DASHBOARD.report.builder,
          },
          {
            parent: "reports",
            title: "fundCredit",
            placement: 1,
            path: PATH_DASHBOARD.report.fund_credit,
          },
          {
            parent: "reports",
            title: "joiningReport",
            placement: 2,
            path: PATH_DASHBOARD.report.joining,
          },
          {
            parent: "reports",
            title: "memberIncome",
            placement: 3,
            path: PATH_DASHBOARD.report.member_income,
          },
          {
            parent: "reports",
            title: "payout",
            placement: 4,
            path: PATH_DASHBOARD.report.payout_report,
          },
          {
            parent: "reports",
            title: "pointHistory",
            placement: 5,
            path: PATH_DASHBOARD.report.point_history,
          },
          {
            parent: "reports",
            title: "sales",
            placement: 6,
            path: PATH_DASHBOARD.report.sales_report,
          },
          {
            parent: "reports",
            title: "topEarners",
            placement: 7,
            path: PATH_DASHBOARD.report.top_earners,
          },
          {
            parent: "reports",
            title: "transaction",
            placement: 8,
            path: PATH_DASHBOARD.report.transaction_report,
          },
        ],
      },
      {
        title: "settings",
        placement: 8,
        path: PATH_DASHBOARD.settings.root,
        icon: ICONS.settings,
        children: [
          {
            parent: "settings",
            title: "brand",
            placement: 0,
            path: PATH_DASHBOARD.settings.brand,
          },
          {
            parent: "settings",
            title: "businessBuilder",
            placement: 1,
            path: PATH_DASHBOARD.settings.business,
          },
          {
            parent: "settings",
            title: "network",
            placement: 2,
            path: PATH_DASHBOARD.settings.network,
            actions: ["rank", "binary", "referral", "bronze", "first-order"],
          },
          {
            parent: "settings",
            title: "Withdrawal",
            placement: 3,
            path: PATH_DASHBOARD.settings.withdrawal,
          },
          {
            parent: "settings",
            title: "emailSettings",
            placement: 4,
            path: PATH_DASHBOARD.settings.email_settings,
          },
          {
            parent: "settings",
            title: "userGuidance",
            placement: 5,
            path: PATH_DASHBOARD.settings.user_guidance,
          },
          {
            parent: "settings",
            title: "termsAndCondition",
            placement: 5,
            path: PATH_DASHBOARD.settings.termsAndCondition,
          },
        ],
      },
      {
        title: "statistics",
        placement: 9,
        icon: ICONS.analytics,
        path: PATH_DASHBOARD.statistics.root,
        actions: ["product-sub-details", "product-sub-user-details"],
      },
      {
        title: "store",
        placement: 10,
        path: PATH_DASHBOARD.store.root,
        icon: ICONS.store,
        children: [
          {
            parent: "store",
            title: "e-comManager",
            placement: 0,
            path: PATH_DASHBOARD.store.products,
            actions: [
              "add",
              "store_payment_methods",
              "edit",
              "questions",
              "videos",
              "document",
              "sample",
              "delete",
              "status",
              "private",
              "url",
            ],
          },
          {
            parent: "store",
            title: "material",
            placement: 1,
            path: PATH_DASHBOARD.store.material.root,
            actions: [
              "add",
              "material-categories",
              "view",
              "delete-category",
              "add-edit-category",
              "delete-material",
              "view-document",
              "delete-document",
              "delete-video",
              "edit-document-video",
            ],
          },
          {
            title: "events",
            parent: "store",
            placement: 2,
            path: PATH_DASHBOARD.store.events,
            actions: ["add", "delete", "view"],
          },
          {
            parent: "store",
            title: "coupons",
            placement: 3,
            path: PATH_DASHBOARD.store.coupons,
            actions: [
              "add",
              "delete",
              "view",
              "edit",
              "visitor",
              "single-delete",
            ],
          },
          {
            parent: "store",
            title: "userReviews",
            placement: 4,
            path: PATH_DASHBOARD.store.user_reviews,
            actions: ["add", "view", "delete", "edit"],
          },
          {
            parent: "store",
            title: "businessBuilderStatistics",
            placement: 5,
            path: PATH_DASHBOARD.store.businessBuilderStatistics,
          },
          {
            parent: "store",
            title: "telegramPageLinks",
            placement: 6,
            path: PATH_DASHBOARD.store.telegram_page_links,
            actions: ["add", "edit"],
          },
          {
            parent: "store",
            title: "userSubscriptions",
            placement: 7,
            path: PATH_DASHBOARD.store.user_subscriptions,
            actions: ["manage"],
          },
          {
            parent: "store",
            title: "User Cart",
            placement: 8,
            path: PATH_DASHBOARD.store.cart,
            actions: [],
          },
          {
            parent: "store",
            title: "Recurring Subscriptions",
            placement: 9,
            path: PATH_DASHBOARD.store.recurringSubscription,
            actions: [],
          },
        ],
      },
      {
        disabled: true,
        title: "subAdmin",
        path: PATH_DASHBOARD.subAdmin.root,
        icon: ICONS.subadmin,
        children: [
          {
            title: "subAdmins",
            path: PATH_DASHBOARD.subAdmin.sub_admins,
          },
        ],
      },
      {
        title: "tools",
        placement: 11,
        path: PATH_DASHBOARD.tools.root,
        icon: ICONS.tools,
        children: [
          {
            parent: "tools",
            title: "documents",
            placement: 0,
            path: PATH_DASHBOARD.tools.documents,
            actions: ["upload-edit", "view", "delete"],
          },
          {
            parent: "tools",
            title: "videos",
            placement: 1,
            path: PATH_DASHBOARD.tools.videos,
            actions: ["add-edit", "delete"],
          },
        ],
      },
      // {
      //   title: "Tokens",
      //   placement: 12,
      //   path: PATH_DASHBOARD.tokens.root,
      //   icon: ICONS.tafToken,
      // },
      {
        title: "Whisper Drop",
        placement: 13,
        path: PATH_DASHBOARD.whisperDrop.root,
        icon: ICONS.user,
      },
    ],
  },
];

export default navConfig;
