import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const List = Loadable(lazy(() => import("src/pages/dashboard/InvoiceList")));
const Details = Loadable(
  lazy(() => import("src/pages/dashboard/InvoiceDetails"))
);
const Create = Loadable(
  lazy(() => import("src/pages/dashboard/InvoiceCreate"))
);
const Edit = Loadable(lazy(() => import("src/pages/dashboard/InvoiceEdit")));
const UserInvoice = Loadable(
  lazy(() => import("src/pages/dashboard/components/Invoice/LatestSaleInvoice"))
);

const BusinessBuilderInvoice = Loadable(
  lazy(() => import("src/pages/userSide/businessBuilder/Invoice/Index"))
);

const invoice = [
  {
    path: "invoice",
    children: [
      {
        element: <Navigate to="/dashboard/invoice/list" replace />,
        index: true,
      },
      { path: "list", element: <List /> },
      {
        path: ":id",
        children: [
          { index: true, element: <Details /> },
          { path: "edit", element: <Edit /> },
        ],
      },
      { path: "new", element: <Create /> },
      { path: "user_invoices", element: <UserInvoice /> },
      { path: "business-builder/:id", element: <BusinessBuilderInvoice /> },
    ],
  },
];

export default invoice;
