import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const SubAdmin = Loadable(lazy(() => import("src/pages/subadmin/index")));

const AddSubAdminGroup = Loadable(
  lazy(() => import("src/pages/subadmin/groups/index"))
);
const SubAdminProfile = Loadable(
  lazy(() => import("src/pages/subadmin/Profile"))
);
const AddSubAdmin = Loadable(
  lazy(() => import("src/pages/subadmin/subadmins"))
);

const Active = Loadable(lazy(() => import("src/pages/subadmin/active/index")));
const InActive = Loadable(
  lazy(() => import("src/pages/subadmin/inactive/index"))
);

const Trashed = Loadable(
  lazy(() => import("src/pages/subadmin/trashed/index"))
);

const subAdmin = [
  {
    path: "sub-admin",
    children: [
      { index: true, element: <Navigate to="list/active" /> },
      {
        path: "list",
        element: <SubAdmin />,
        children: [
          { path: "active", element: <Active /> },
          { path: "in-active", element: <InActive /> },
          { path: "trashed", element: <Trashed /> },
        ],
      },
      { path: ":sid", element: <SubAdminProfile /> },
      { path: "add", element: <AddSubAdmin /> },
      {
        path: "group",
        children: [
          { index: true, element: <Navigate to="add" /> },
          { path: ":sid", element: <AddSubAdminGroup /> },
          { path: "add", element: <AddSubAdminGroup /> },
        ],
      },
    ],
  },
];

export default subAdmin;
