import { lazy } from "react";
import { Navigate } from "react-router";
import Loadable from "src/routes/Loadable";

const Business = Loadable(
  lazy(() => import("src/pages/dashboard/business/index"))
);
const Network = Loadable(lazy(() => import("src/pages/dashboard/network")));
const LatestSaleInvoice = Loadable(
  lazy(() => import("src/pages/dashboard/InvoiceDetails"))
);
const MonthlySalesReport = Loadable(
  lazy(() => import("src/pages/dashboard/components/SalesDetails/Index"))
);
const NetworkBonusDetails = Loadable(
  lazy(() =>
    import("src/pages/dashboard/network/components/NetWorkBonusDetails/Index")
  )
);
const ProductSalesReport = Loadable(
  lazy(() =>
    import("src/pages/dashboard/components/SalesDetails/ProductSalesReport")
  )
);
const ProductPurchasedUser = Loadable(
  lazy(() =>
    import(
      "src/pages/dashboard/components/SalesDetails/ProductSalesReport/components/ProductPurchasedUser"
    )
  )
);

const dashboard = [
  {
    path: "dashboard",
    children: [
      { index: true, element: <Navigate to="business" /> },
      {
        path: "business",
        children: [
          { index: true, element: <Business /> },
          { path: ":id", element: <LatestSaleInvoice /> },
          {
            path: "salesReport",
            children: [
              { index: true, element: <MonthlySalesReport /> },
              {
                path: "monthly-product-sales-report",
                element: <ProductSalesReport />,
              },
              { path: ":product_id", element: <ProductPurchasedUser /> },
            ],
          },
        ],
      },

      {
        path: "network",
        children: [
          { index: true, element: <Network /> },
          { path: "monthly-network-bonus", element: <NetworkBonusDetails /> },
        ],
      },
    ],
  },
];

export default dashboard;
