import { useLocation, useNavigate } from "react-router-dom";
import {
  encodeObjectToQueryString,
  parseQueryString,
} from "src/utils/queryString";

const useQueryParams = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const queryObject = parseQueryString(search);

  const handleNavigate = (obj, state) => {
    navigate(
      {
        pathname,
        search: encodeObjectToQueryString(obj),
      },

      { replace: true, state }
    );
  };

  const addForce = (data) => {
    handleNavigate(data);
  };

  const addParam = (k, v, state) => {
    if (typeof k === "object") {
      handleNavigate({ ...queryObject, ...k }, state);
      return;
    }

    handleNavigate({ ...queryObject, [k]: v }, state);
  };

  const deleteParam = (...keys) => {
    const temp = { ...queryObject };
    keys.forEach((item) => delete temp[item]);
    handleNavigate(temp);
  };

  const deleteListOfKeys = (keys, state) => {
    const temp = { ...queryObject };
    keys.forEach((item) => delete temp[item]);
    handleNavigate(temp, state);
  };

  const clear = () => {
    handleNavigate({});
  };

  return {
    addForce,
    queryObject,
    addParam,
    deleteParam,
    clear,
    deleteListOfKeys,
  };
};

export default useQueryParams;
